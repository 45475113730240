@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Jaro:opsz@6..72&family=Londrina+Outline&family=Silkscreen:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,800;1,800&display=swap');

body {
  margin: 0;
  background-color:rgb(209 213 219);;
}

html {
  scroll-behavior: smooth;
  /* overflow-x: hidden; */
}
